<template>

    <div id="app">
      <v-row>
        <v-col md="5" lg="4">
                  <v-select
                          :items="listpays"
                          v-model="selectpays"
                          dense
                          item-value="id"
                          :rules="[v => !!v || 'Veuillez selectionnez le le pays']"
                          required
                          outlined
                          label="Liste pays">
                          <template slot="selection" slot-scope="data">
                              {{ data.item.code_pays }} - {{ data.item.libelle }}
                              </template>
                            <template slot="item" slot-scope="data">
                              {{ data.item.code_pays }} - {{ data.item.libelle }}
                              </template>
                          </v-select>
  
            </v-col>
             <v-col md="5" lg="4">
                    <v-text-field
                      v-model="libelle"
                      label="Recherche..."
                      dense
                      outlined
                      required
                     
                    ></v-text-field>
        </v-col>
        

      </v-row>
         
                   
        <v-data-table
    :headers="headers"
    :items="list"
    sort-by="libelle"
    class="elevation-1"
  >
     <template v-slot:[`item.image`]="{ item }">
          <div class="p-2">
            <v-img :src="lienimage+item.image" :alt="item.libelle" height="50px" width="50"></v-img>
          </div>
     </template>
     <template v-slot:top>
      <v-toolbar
        flat
      >
              <v-toolbar-title>Liste des programme par pays</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
        
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
        </template>
        </v-snackbar>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
             @click="nouveau()" 
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                <v-select
                 :items="listpays"
                 v-model="selectpays"
                 dense
                 item-value="id"
                 :rules="[v => !!v || 'Veuillez selectionnez le le pays']"
                 required
                 outlined
                 label="Liste pays">
                 <template slot="selection" slot-scope="data">
                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                    </template>
                 </v-select>

                    <v-text-field
                      v-model="libelle"
                      label="Libelle programme gouvernement"
                      dense
                      outlined
                      required
                     
                    ></v-text-field>
             
                </v-form>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Annuller</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Actualiser la liste
      </v-btn>
    </template>
  </v-data-table>
        

    </div>

</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import api from './../../../serviceApi/apiService'
export default {
    name: 'ODD',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'ODD'
    },
    components: {
        VuePerfectScrollbar
    },


      mounted() {
        this.readAll();
      }, 
    data: () => ({
      multiLine: true,
      snackbar: false,
      snackbars:false,
      text: `message`,
      textsnackbar:'messages',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID', value: 'id' ,divider: true,width: '5%'},
        { text: 'Libelle Programme', value: 'libelle' ,divider: true,width: '40%'},       
        { text: 'Pays', value: 'pays.libelle' ,divider: true,width: '20%'},       
        { text: 'Actions', value: 'actions', sortable: false ,divider: true,width: '10%'},
        
      ],
      
      formTitle:'Formulaire',
      
      list:[],
      listpays:[],
      libelle:'',
      selectpays:'0',

      id:'',
      idpays:'',
      textetat:false,
      messages:'',
    }),
    methods: {
           readAll: async function() {
                const data = await api.readAll('programe_pag/all-programme-gouvernement');
                this.list = data.list;
                 // console.log(data.list);
          },

           readpaysAll: async function() {
                const data = await api.readAll('globale-geo/all-pays');
                this.listpays = data.list;
                //console.log(data.list);
          },

          deleteData: async function() {
               let fd=new FormData();
              fd.append('id',this.id);
             const res=await api.createUpdatedata('programe_pag/add-programme-gouvernement/delete',fd);   
              //this.messagesligne(res);
            this.text=res.message;
         },

          createUpdatedata: async function() {
            
          let fd=new FormData();
          fd.append('id_pays',this.selectpays);
          fd.append('libelle',this.libelle);
      
      if(this.textetat){
          
        const res=await api.createUpdatedata('programe_pag/update-programme-gouvernement/'+this.id_odds,fd);   
          if(res.status==200){
          this.text=res.message;
          this.snackbar=true;
          }
          
        }else{
          const res=await api.createUpdatedata('programe_pag/add-programme-gouvernement',fd);    
         this.text=res.message;
          this.snackbar=true;
         }
        this.readAll();
         this.clear();
        
        },
        nouveau(){
          this.libelle = "";
          this.idpays = "";
          this.textetat=false;
          
       },
    
     
       clear(){
          this.libelle = "";
          this.idpays = "0";
          this.textetat=false;
       },
      deleteItem(item){
          this.id=item.id;
          this.dialogDelete=true;
         
       },
       editItem(item){
        this.id=item.id;
        this.libelle = item.libelle;
        this.idpays = item.id_pays;
        this.textetat=true;
        this.dialog = true;
       },

       deleteItemConfirm(){
        this.deleteData();
        this.readAll();
        this.dialogDelete=false
       },

       
     
       save () {
         if(this.libelle==''||this.selectpays==''){
          
           this.messages="Veuillez remplir les champs";
           this.text="Veuillez remplir les champs";
           this.snackbar=true;
           return;
         }
         this.createUpdatedata();
          
       },
    
   
    },

       mounted() {
        this.readAll();
        this.readpaysAll();
        //this.readindicateurAll();
      }, 
}
</script>
<style lang="scss" scoped>
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>
